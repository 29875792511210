<template>
    <div class="dashboard-home">
        <!-- hero banner -->
        <div class="hero-banner">
            <div v-if="sliderLoading" class="loading-sider container container-xl">
                <b-skeleton animation="wave" height="100%" width="100%" 
                style="border-radius: 0 !important"></b-skeleton>
            </div>
            <div v-else class="container container-xl">
                <!-- :paginationEnabled="false" -->
                <carousel
                    :rtl="isRTL"
                    :perPage="1"
                    :autoplayTimeout="7000"
                    :loop="true"
                    :autoplay="true"
                    :navigationEnabled="true"
                    :navigationNextLabel="`<img width='70' src='${img}'>`"
                    :navigationPrevLabel="`<img width='70' src='${img2}'>`"
                >
                    <slide v-for="(slide, index) in slider" :key="index">
                        <a :href="slide.link" style="width: 100%; height: 100%;">
                            <img
                                :src="slide.image"
                                class="img-fluid"
                            />
                        </a>
                        <!-- <div class="join-us">
                            <h2 class="join-title text-brand font-weight-600">
                                Join us
                            </h2>
                            <p>
                                and follow the constant renewal of your business
                            </p>
                            <router-link to="/" class="btn"
                                >Join us</router-link
                            >
                        </div> -->
                    </slide>
                </carousel>
            </div>
        </div>

        <!-- progress circles -->
        <div class="progress-circles">
            <div class="container container-xl">
                <div class="d-flex flex-wrap justify-content-center">
                    <div
                        v-for="circle in circles"
                        :key="circle.id"
                        class="circle px-1 px-sm-3 mb-3"
                    >
                        <radial-progress-bar
                            :diameter="diameter"
                            :completed-steps="completedSteps"
                            :total-steps="totalSteps"
                            :startColor="circle.color"
                            :stopColor="circle.color"
                            :strokeWidth="4"
                            :innerStrokeWidth="12"
                            innerStrokeColor="#f5f5f5"
                        >
                            <span class="num">{{ getValue(circle.type) }}</span>
                            <div
                                class="
                                    title-container
                                    d-flex
                                    flex-column
                                    align-items-center
                                "
                            >
                                <small class="title">{{ circle.title1 }}</small>
                                <small class="title">{{ circle.title2 }}</small>
                            </div>
                        </radial-progress-bar>
                    </div>
                </div>
            </div>
        </div>

        <!-- Therapeutic Area‏ -->
        <div class="therapeutic-area‏">
            <div class="container container-xl">
                <div class="head text-center mb-5 pb-3">
                    <h2 class="font-weight-600 text-uppercase">
                        Therapeutic Area‏
                    </h2>
                    <p class="subhead mx-auto text-color">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots
                    </p>
                </div>
                <div class="features-items row align-items-center">
                    <template v-if="loadingSpecilty">
                        <span
                        v-for="item in 10"
                        :key="item"
                        class="
                            col-6 col-lg-4 col-xl-3
                            mb-4
                            d-flex
                            flex-column
                            align-items-center
                            justify-content-center
                        "
                    >
                        <b-skeleton class="img-circle mb-3" width="4rem" height="4rem"></b-skeleton>
                        <b-skeleton width="9rem" height="1rem"></b-skeleton>
                    </span>
                    </template>
                    <template v-else>
                        <router-link
                            v-for="(specialty, index) in specialties"
                            :key="index"
                            :to="`/therapeutic-area/${ specialty.id }`"
                            class="
                                col-6 col-lg-4 col-xl-3
                                mb-4
                                d-flex
                                flex-column
                                align-items-center
                                justify-content-center
                            "
                        >
                            <img
                                :src="specialty.image"
                                :alt="specialty.name"
                                class="mb-3"
                            />
                            <span class="text-color">{{ specialty.name }}</span>
                        </router-link>
                    </template>
                </div>
                <div
                    class="
                        more
                        d-flex
                        justify-content-center
                        align-items-center
                        mt-5
                    "
                >
                    <router-link
                        to="/therapeutic-area"
                        class="
                            more-link
                            d-flex
                            justify-content-center
                            align-items-center
                        "
                    >
                        <span></span>
                        More
                        <span></span>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- module section -->
        <div class="module-section">
            <div class="container container-xl">
                <div class="head text-center mb-5 pb-2">
                    <h2 class="font-weight-600 text-uppercase">Module</h2>
                    <p class="subhead mx-auto text-color">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots
                    </p>
                </div>

                <!-- filter module type -->
                <div class="module-tabs w-100 d-flex justify-content-center mb-4">
                    <ul
                        class="
                            d-flex
                            flex-wrap
                            justify-content-center
                            align-items-center
                        "
                    >
                        <li class="mx-1">
                            <button
                                @click="
                                moduleType = 'featured';
                                getModules();
                                "
                                :class="[
                                    moduleType == 'featured' ? 'active' : '',
                                ]"
                                :disabled="moduleType == 'featured'"
                                class="btn">
                                Featured Module
                            </button>
                        </li>
                        <li class="mx-1">
                            <button
                                @click="
                                moduleType = 'recommend';
                                getModules();
                                "
                                :class="[
                                    moduleType == 'recommend' ? 'active' : '',
                                ]"
                                :disabled="moduleType == 'recommend'"
                                class="btn">
                                Recommended for you
                            </button>
                        </li>
                        <li class="mx-1">
                            <button
                                @click="
                                moduleType = 'visit';
                                getModules();
                                "
                                :class="[
                                    moduleType == 'visit' ? 'active' : '',
                                ]"
                                :disabled="moduleType == 'visit'"
                                class="btn">
                                Recently visited
                            </button>
                        </li>
                        <li class="mx-1">
                            <button
                                @click="
                                moduleType = 'new';
                                getModules();
                                "
                                :class="[moduleType == 'new' ? 'active' : '']"
                                :disabled="moduleType == 'new'"
                                class="btn">
                                New Module
                            </button>
                        </li>
                    </ul>
                </div>

                <div class="row">
                    <template v-if="loadingModules">
                        <div v-for="(mod, index) in 4" :key="index" class="col col-xl-3 d-flex justify-content-center">
                            <div class="module-card mx-auto">
                                <div class="img mw-100 mb-3">
                                    <b-skeleton width="100%" height="9rem"></b-skeleton>
                                </div>
                                <ul class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center contains mb-3">
                                    <li v-for="(tag, index) in 3" :key="index">
                                        <div class="contains-item d-flex align-items-center">
                                            <b-skeleton width="3rem" height="1.2rem"></b-skeleton>
                                        </div>
                                    </li>
                                </ul>
                                <div class="progress mb-3">
                                    <b-skeleton width="45%" height="100%"></b-skeleton>
                                </div>
                                <h5 class="title text-capitalize">
                                    <b-skeleton width="8rem" height="1.1rem"></b-skeleton>
                                </h5>
                                <p class="sepciality text-capitalize">
                                    <b-skeleton width="6rem" height="0.8rem"></b-skeleton>
                                </p>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <div class="d-flex align-items-center">
                                        <b-skeleton class="img-circle" width="45px" height="45px"></b-skeleton>
                                        <small class="owner-name mx-2 text-capitalize">
                                            <b-skeleton width="5rem" height="0.7rem"></b-skeleton>
                                        </small>
                                    </div>
                                    <span class="price">
                                        <b-skeleton width="4rem" height="0.7rem"></b-skeleton>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!loadingModules && modules.length">
                        <div v-for="(module, index) in modules" :key="index" class="col col-xl-3 d-flex justify-content-center">
                            <ModuleCard 
                            :cardData="module" 
                            @storedAsFavorite="getModules()"/>
                        </div>
                    </template>
                    <div v-else 
                    class="col-md-7 col-lg-6 mx-auto text-center my-5">
                        <p class="no-modules mx-auto py-2">{{ $t('noResults') }}</p>
                    </div>
                    <!-- <div
                        v-for="card in 10"
                        :key="card"
                        class="col col-xl-3 d-flex justify-content-center"
                    >
                        <ModuleCard :cardData="cardData" />
                    </div> -->
                </div>
            </div>
        </div>

        <!-- partners section -->
        <div class="partners-section">
            <div class="container">
                <Partners />
            </div>
        </div>

        <!-- FAQ -->
        <div class="faq pt-5">
            <div class="container container-xl pb-5 mb-md-4">
                <div class="head text-center mb-5 pt-2">
                    <h2 class="font-weight-600 text-uppercase">
                        FREQUENTLY ASKED QUESTIONS
                    </h2>
                </div>
                <FAQ />
            </div>
        </div>

        <TopFooterAds />
    </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";
import FAQ from "@/components/FAQ";
import TopFooterAds from "@/components/TopFooterAds";
import ModuleCard from "@/components/ModuleCard";
import Partners from "@/components/Partners";

import { getModulesByType } from "@/API/modules";
import { getUserSlider } from "@/API/slider";
import { getHomeSpecialty } from "@/API/specialties";


export default {
    name: "DashboardHome",
    components: {
        RadialProgressBar,
        FAQ,
        TopFooterAds,
        ModuleCard,
        Partners,
    },
    data() {
        return {
            slider: [],
            sliderLoading: true,
            isRTL: this.$store.getters['layout/direction'] == 'rtl',
            img: require("@/assets/imgs/arrow-right.png"),
            img2: require("@/assets/imgs/arrow-left.png"),
            diameter: 160,
            circles: [
                {
                    id: 1,
                    title1: "User ID",
                    title2: "",
                    type: 'id',
                    color: "#A083F3",
                },
                {
                    id: 2,
                    title1: "CME",
                    title2: "Accredited",
                    type: 'accredited',
                    color: "#FFD064",
                },
                {
                    id: 3,
                    title1: "Modules",
                    title2: "",
                    type: 'modules',
                    color: "#25D49F",
                },
                {
                    id: 4,
                    title1: "Total",
                    title2: "CME / CPD‏",
                    type: 'total',
                    color: "#F383AA",
                },
            ],
            completedSteps: 10,
            totalSteps: 10,
            loadingModules: true,
            moduleType: "featured",
            loadingSpecilty: true,
            specialties: [],
            modules: [],
        };
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'] || {}
        },
        userStats() {
            return this.$store.getters['auth/userStats']
        }
    },
    methods: {
        getValue(type) {
            if (type == 'total') {
                return this.userStats.total_accredited || 0
            }

            if (type == 'accredited' && this.userStats.CME) {
                return `${this.userStats.cme_accredited || 0}/${this.userStats.CME}`
            }

            if (type == 'modules') {
                return this.userStats['modules'] || 0
            }

            if (type == 'id') {
                return this.userStats.userID
            }

            return 0
        },
        getModules() {
            this.loadingModules = true;
            getModulesByType(this.moduleType).then(res => {
                this.loadingModules = false;
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log('type', data);
                    if (data?.module) this.modules = data.module;
                    return;
                }

                this.modules = [];
            });
        },
        userSlider() {
            getUserSlider().then(res => {
                if (res.status == 200) {
                    // console.log(slider);
                    const slider = res.data?.data?.userSlider;
                    this.slider = slider;
                    this.sliderLoading = false;
                }
            });
        },
        homeSpecialties() {
            getHomeSpecialty().then(res => {
                if (res.status == 200) {
                    const response = res.data?.data?.specialty;
                    // console.log(response);
                    this.specialties = response || [];
                }
                this.loadingSpecilty = false;
            })
        }
    },
    mounted() {
        this.userSlider();

        if (window.innerWidth < 991) this.diameter = 150;
        if (window.innerWidth < 580) this.diameter = 130;
        if (window.innerWidth < 380) this.diameter = 120;

        this.homeSpecialties();
        this.getModules();
    },
};
</script>
